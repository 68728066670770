@use "../../../theme/vars.scss";
@use "../../../theme/zIndexes.scss";

.visualizerContent {
  overflow: auto;
  position: relative;
}
.visualizationContainer {
  display: flex;
  align-items: center;
}

.visualizationResultsActions {
  display: flex;
  margin-left: auto;
}

.visualizationTab {
  border-radius: 0;
  border-top: none;
  border-bottom: none;
  text-transform: none;
  &:first-of-type {
    border-left: none;
  }
  &:global(.Mui-disabled) {
    border-top: none;
    border-bottom: none;
  }
}

.visualizationDropdownTab {
  border-right: none;
  &:global(.Mui-disabled) {
    border-right: none;
  }
}

.visualizationDropdownCaret {
  border-left: none;
  padding-left: 0px;
  padding-right: 0px;
  &:global(.Mui-disabled) {
    border-left: none;
  }
}
.downloadListHeader {
  line-height: 28px;
}
.downloadListItem:hover {
  color: inherit;
}

.documentationButton {
  min-height: 35px;
  min-width: 35px;
  max-width: 35px;
}
.popper {
  z-index: zIndexes.$popper-z + 1;
}
.documentationPaper {
  overflow: none;
  min-width: 500px;
  max-width: 90vw;
  @media screen and (max-width: 500px) {
    min-width: 300px;
  }
}
.documentationContent {
  height: 100%;
  overflow: auto;
  max-height: 70vh;
}
.downloadList {
  z-index: zIndexes.$popper-z + 1;
}

@media print {
  //Excluding error msgs from print preview
  :global(.responseErrorMessage) {
    display: none;
  }

  :global(.noResultsMessage) {
    display: none;
  }
}
