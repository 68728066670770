@use "../../theme/vars.scss";

.muiOverride {
  min-width: 300px;
  width: 400px;
  max-width: 400px;
  flex-grow: 1;
  // Mui selects don't add the correct class when a value is empty
  fieldset legend {
    max-width: 10000px;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  }
  :global(.MuiOutlinedInput-notchedOutline) {
    border-radius: 4px;
  }
}
.placeholder {
  color: var(--color-gray-light);
  option {
    color: var(--color-gray-dark);
  }
}
