.noResourceContainer {
  height: fit-content;
}
.timeline {
  :global(.MuiTimelineOppositeContent-root) {
    flex: 0.2;
    max-width: 150px;
  }
  .timelineDot {
    width: 100px;
    max-width: 100px;
  }
}
.keepLines {
  white-space: pre-wrap;
}

.borderless {
  border: unset;
}
.block {
  background: #{"hsl(from var(--color-primary) h s l / 0.1)"};
}
.instancesGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .instanceItem {
    display: flex;
    justify-content: space-between;
    word-break: break-word;
    padding: 0.5rem;
    gap: 1rem;
    &:nth-child(odd) {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      padding-left: 0;
      padding-right: 1.5rem;
    }
    &:nth-child(even) {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      padding-left: 1.5rem;
      padding-right: 0;
    }
  }
}
